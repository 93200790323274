import { message, Modal } from 'antd';
import moment from 'moment';
import { parsePhoneNumber } from 'awesome-phonenumber';
import * as rdc from 'react-device-detect';
import config from './config';
import { LoadingMin } from 'components';
import { types } from 'helpers';

export const isJson = (str: any) => {
    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
}


// ::: storage
export const setStorage = (key: string, value: string) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, value);
    }
}
export const getStorage = (key: string) => {
    const value = localStorage.getItem(config.dbpref + key);
    return value || '';
}
export const setStorageJson = (key: string, value: any) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, JSON.stringify(value));
    }
}
export const getStorageJson = (key: string) => {
    if (key) {
        const value = localStorage.getItem(config.dbpref + key) || '';
        return isJson(value) ? JSON.parse(value) : '';
    }
}
export const delStorage = (key: string) => {
    if (key) {
        localStorage.removeItem(config.dbpref + key);
    }
}

export const redirect = (to: any) => {
    window.location = to;
}

export const openNewTab = (to: any) => {
    window.open(to, '_blank');
}

export const generateOptions = (length: number, step = 1) => {
    const arr = [];
    for (let value = 0; value < length; value += step) {
        arr.push(value);
    }
    return arr;
};

export const format = {
    date: (date: string) => isToday(date) ? `Today @ ${moment(date).format('H:mm A')}` : moment(date).format('MMM DD, YYYY HH:mm:ss'),
    number: (number: number, decimal = 3) => {
        number = number || 0;
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: decimal === 0 ? 0 : 2, maximumFractionDigits: decimal }).format(number);
    },
    currency: (amount: number | string, currency: 'GHS' = 'GHS') => {
        return `${currency} ${format.number(+amount)}`;
    },
    phoneNumber: (msisdn: string) => {
        if (msisdn) {
            const regionCode = msisdn.includes('+') ? undefined : 'GH';
            const parsed = parsePhoneNumber(msisdn, { regionCode });
            return parsed.valid ? parsed.number.international : (msisdn.length > 20 ? msisdn : msisdn.match(/.{1,3}/g)?.join(' '));
        }
        return '';
    },
}


export const randNum = (length = 6) => {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const randNumRange = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}

export const randCode = (length = 6) => {
    let result = '';
    const characters = 'ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const imgError = (e: any) => {
    e.target.src = '/download.svg';
}

export const objectToArray = (obj: any) => {
    let data = [];
    if (Array.isArray(obj)) {
        data = obj;
    } else {
        for (let i = 0; i < Object.keys(obj).length; i++) {
            data.push(obj[i]);
        }
    }
    return data;
}

export const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then((_) => {
        message.success(`Copied`);
    });
}

export const md5 = (str: any) => {
    return btoa(JSON.stringify(str));
}

export const isToday = (date: string) => !!(moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'));

export const daysBetweenDates = (startDate: any, endDate: any, duration: 'days' | 'months' = 'days') => {
    const dates = [];
    const format = { days: 'YYYY-MM-DD', months: 'YYYY-MM' };
    while (moment(startDate) <= moment(endDate)) {
        dates.push(startDate);
        startDate = moment(startDate).add(1, duration).format(format[duration]);
    }
    return dates;
}

export const isLocal = window.location.host.includes('localhost');
export const isStaging = window.location.host.includes('stage-');

export const ucFirst = (str: string) => {
    return str ? str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1) : '';
}

export const ucWords = (str: string) => {
    return str ? str.split(' ').map(str => { return ucFirst(str); }).join(' ') : '';
}

export const copyObject = (a: any) => JSON.parse(JSON.stringify(a));

export const isMobile = !!(rdc.isMobile && !rdc.isTablet);

export const hasPermission = (role: string) => {
    const user: types.User = isJson(getStorageJson('auth').user) ? JSON.parse(getStorageJson('auth').user) : {};
    if (user.access?.access) {
        return user.access.access.includes(role);
    }
    return true;
}

export const requiredInput = [{ required: true, message: 'This field is required' }];

export const smsCount = (e: string) => {
    if (e) {
        const part1 = 158;
        const part2 = 145;
        const part3 = 152;
        let pages = 0;
        // let remaining = 0;
        const chars = e.length;
        if (chars <= part1) {
            pages = 1;
            // remaining = part1 - chars;
        } else if (chars <= (part1 + part2)) {
            pages = 2;
            // remaining = (part1 + part2) - chars;
        } else if (chars > (part1 + part2)) {
            const moreM = Math.ceil((chars - part1 - part2) / part3);
            pages = 2 + moreM;
            // remaining = part1 + part2 + (moreM * part3) - chars;
        }
        return (`${chars} / ${pages} SMS`);
    }
    return ('0 / 0 SMS');
}

export const loading = (content: any) => {
    return new Promise((resolve) => {
        content = (
            <div className="flex justify-center items-center gap-2">
                <LoadingMin />
                <div className="text-gray-500 text-sm">{content}</div>
            </div>
        );
        const loading = Modal.info({
            icon: null,
            title: null,
            centered: true,
            content,
            width: '250px',
            className: 'loading',
        });
        resolve(loading);
    }) as any;
}

export const filterQuery = (filters: types.Filter[]) => {
    const query: any = {};
    for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        query[filter.key] = filter.value || '';
        if (filter.type === 'date_range' && filter.value) {
            // query['date_to'] = filter.value.split(',')[1];
            // query['date_from'] = filter.value.split(',')[0];

            query['between'] = `crdate_${filter.value.split(',')[0]}_${filter.value.split(',')[1]}`;
        }
        if (!query[filter.key]) delete query[filter.key];
    }
    return query;
}

export const sortString = (a: any, b: any, key: string) => {
    const fa = a[key].toLowerCase();
    const fb = b[key].toLowerCase();

    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
}

export const versionBreak = (v: string) => v ? Number(v.split('.').join('')) : 0;