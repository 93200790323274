import React from 'react';
import _Store from '_Store';
import { Modal, Popover } from 'antd';
import MaterialIcon from './MaterialIcon';
import { axius, config, helpers, types } from 'helpers';


interface Props {
  setSide: () => void;
}
const Header = ({ setSide }: Props) => {

  const { _store: { _auth: { sso, user } } } = React.useContext(_Store);

  const [products, setProducts] = React.useState<types.Product[]>([]);

  React.useEffect(() => {
    axius.get(`${config.sso_api}products`).then(res => {
      res.status === 200 && setProducts(((res.data || []) as types.Product[]).filter(p => p.code !== 'FAYASMS_ADMIN'));
    });
    // eslint-disable-next-line
  }, []);

  const name = sso.name?.split(' ');

  const qwerty = `justify-center items-center h-[40px] w-[40px] cursor-pointer p-1 rounded-full hover:bg-gray-200 transi`;

  const goToApp = (product: types.Product) => {
    if (product.url) {
      if (product.requires_document && !sso.compliance?.document?.status) {
        Modal.confirm({
          icon: null,
          title: `Identity Verification`,
          width: 350,
          content: `${product.name} requires we verify your identity. We would need more information about you to proceed.`,
          okText: `Sure, Proceed`,
          cancelText: `Cancel`,
          centered: true,
          onOk: () => {
            helpers.redirect(config.sso_app + `verification`);
          }
        });
      } else {
        helpers.redirect(`${product.url[config.env]}?sso=${helpers.getStorage('sso')}`);
      }
    } else {
      return false;
      Modal.info({
        icon: null,
        title: `Request Demo`,
        width: 350,
        content: `We want to show you how ${product.name} works. Write us at anchoratechs@gmail.com`,
        centered: true,
      });
    }
  }

  const appsView: any = 'list';

  return (
    <React.Fragment>
      {user?.uuid && (
        <div className="sticky top-0 z-10 w-full bg-gray-100s">

          <div id="header_desktop" className="flex justify-center shadow-lgs bg-primary-lights- bg-[#F9F9F9]s">
            <div className="flex justify-between items-center w-11/12 xl:w-10/12 py-3 xl:py-4">
              <div>
                <div className={`${qwerty} flex xl:hidden`} onClick={setSide}>
                  <MaterialIcon name="menu" />
                </div>
              </div>
              <div className="flex items-center gap-6">
                <Popover
                  trigger={['click']} arrow
                  content={(
                    <>
                      {appsView === 'list' ? (
                        <div className="w-[350px] p-2 border-4 rounded-xl">
                          {products.map(product => (
                            <div
                              key={product.name}
                              className={`flex gap-3 items-center px-4 py-2 rounded-lg transi ${product.url ? 'pops hover:bg-gray-100 cursor-pointer' : 'opacity-60 cursor-auto'}`}
                              onClick={() => goToApp(product)}
                            >
                              <img src={product.icon} alt={product.name} className="h-[30px] p-1 rounded-full border" />
                              <div className="w-10/12">
                                <div className="font-semibold text-sm mt-2s flex items-center gap-2">
                                  {product.name}
                                  {product.type === types.UserTypes.ADMIN && (
                                    <div className="px-2 rounded-lg text-xs bg-green-200 text-green-700 hidden">ADMIN</div>
                                  )}
                                </div>
                                <div className="text-xs mt-2s text-gray-500 truncate">{product.description}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-[350px] px-5 py-8 grid grid-cols-3 gap-6 border-4 rounded-xl">
                          {products.map(product => (
                            <div
                              key={product.name}
                              className={`text-center p-2 rounded-lg transi ${product.url ? 'pop hover:bg-[#27ba7c0f] hover:bg-gray-50 cursor-pointer' : 'opacity-60'}`}
                              onClick={() => goToApp(product)}
                            >
                              <div className="flex justify-center"><img src={product.icon} alt={product.name} className="h-[30px]" /></div>
                              <div className="font-medium text-sm mt-2">{product.name}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                >
                  <div className={`${qwerty} flex`}>
                    <MaterialIcon name="apps" className="text-3xl" />
                  </div>
                </Popover>
                <div>
                  <Popover
                    trigger="click"
                    content={(
                      <div className="w-[200px]">
                        <div className="px-4 pt-4 text-center">
                          <div className="uppercase font-semibold truncate">{user.name}</div>
                          <div className="text-sm text-gray-500 truncate">{user.email}</div>
                        </div>
                        <div className="border-b mt-5 mb-4" />
                        <div className="flex gap-2 items-center text-sm text-gray-500 cursor-pointer py-2 px-4 transi hover:bg-gray-50" onClick={() => helpers.redirect(config.sso_app + 'account')}>
                          <MaterialIcon name="account_circle" />
                          <div>Manage Account</div>
                        </div>
                        <div className="flex gap-2 items-center text-sm text-gray-500 cursor-pointer py-2 px-4 transi hover:bg-gray-50" onClick={axius.signOutSuccess}>
                          <MaterialIcon name="logout" />
                          <div>Logout</div>
                        </div>
                        <div className="pb-5" />
                      </div>
                    )}
                  >
                    <div className="flex items-center cursor-pointer">
                      <div className="flex justify-center items-center h-[40px] w-[40px] font-semibold p-1 rounded-full bg-gray-100 hover:bg-gray-200 transi uppercase">
                        {sso.avatar ? <img src={sso.avatar_link} alt={sso.name} /> : `${name[0][0]}${name[1][0]}`}
                      </div>
                      <MaterialIcon name="keyboard_arrow_down" className="text-xl" />
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
          </div>

        </div>
      )}
    </React.Fragment>
  );
};
export default Header;
